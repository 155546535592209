<template>
    <admin-layout>
        <visible-for role="admin">
            <h1 class="fw-bold text-center mb-4">
                Pridať {{ input.language.value.toUpperCase() }} kurz
            </h1>
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-xl-6">
                    <form @submit.prevent="submitForm">
                        <div class="row mb-3 g-3">
                            <div class="col-12 col-lg">
                                <div class="form-floating">
                                    <input id="title" v-model="input.title.value" :class="'form-control ' + isValidInput(input.title)" placeholder="" type="text">
                                    <label for="title">Názov *</label>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3 g-3">
                            <div class="col-12 col-lg">
                                <div class="form-floating">
                                    <textarea id="description" v-model="input.description.value" :class="'form-control ' + isValidInput(input.description)" placeholder="" rows="4"></textarea>
                                    <label for="description">Popis</label>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row mb-3 g-3">
                            <div class="col-12 col-lg">
                                <div class="form-floating">
                                    <textarea id="groups" :class="'form-control ' + isValidInput(input.groups)" placeholder="" rows="4" @keyup="handleGroupChange"></textarea>
                                    <label for="groups">Skupiny</label>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row mb-3 g-3">
                            <div class="col-12 col-lg">
                                <div class="form-floating">
                                    <select id="language" v-model="input.language.value" :class="'form-select ' + isValidInput(input.language)">
                                        <option v-for="lang in availableLocales" :value="lang">{{ lang.toUpperCase() }}</option>
                                    </select>
                                    <label for="language">Jazyk *</label>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row mb-3">
                            <div class="col-12">
                                <label class="fw-bold mb-1">Expirácia (počet dní)</label>
                                <div class="row g-3">
                                    <div v-for="expiration in courseExpirations" class="col-12 col-lg-4">
                                        <div class="form-floating">
                                            <input :id="'expiration_' + expiration" v-model="input.expiration[expiration].value" :class="'form-control ' + isValidInput(input.expiration[expiration])" min="0" placeholder="" type="number">
                                            <label :for="'expiration_' + expiration">{{ translate(expiration) }} *</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3 g-3">
                            <div class="col-12">
                                <label class="form-label fw-bold mb-1" for="thumbnail">Náhľadový obrázok *</label>
                                <div class="card">
                                    <div class="card-body">
                                        <div v-if="thumbnailSrc" class="ratio ratio-16x9 mt-1 mb-2">
                                            <img :src="thumbnailSrc" class="thumbnail-image" alt="Náhľadový obrázok">
                                        </div>
                                        <div class="d-flex">
                                            <input
                                                id="thumbnail"
                                                ref="thumbnail"
                                                :accept="acceptImageMimeTypes"
                                                :class="`form-control ` + isValidInput(input.thumbnail)"
                                                type="file"
                                                @change.prevent="handleThumbnailChange"
                                            >
                                            <button v-if="thumbnailSrc" class="btn btn-danger ms-1" @click.prevent="handleThumbnailDelete">
                                                <i class="bi bi-trash-fill"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-12">
                                <label class="fw-bold mb-2">Videá</label>
                                <div>
                                    <div v-for="video in videoElements" class="row">
                                        <div class="col-12">
                                            <keep-alive>
                                                <video-upload
                                                    :is="video.element"
                                                    :id="video.id"
                                                    :groups="input.groups.value"
                                                    :key="video.id"
                                                    :handle-change="handleChange"
                                                    :handle-component-delete="removeVideoElement"
                                                    :handle-move-down="handleMoveDown"
                                                    :handle-move-up="handleMoveUp"
                                                    :payload="getVideoPayload(video.id)"
                                                ></video-upload>
                                            </keep-alive>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-primary" type="button" @click.prevent="addVideoElement">
                                    Pridať video
                                </button>
                            </div>
                        </div>
                        <div v-if="uploadProgress !== 0" class="row mb-4">
                            <div class="col-12">
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" :style="'width: ' + uploadProgress + '%'">{{ uploadProgress }} %</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center">
                                <submit-button :disabled="fetching" :fetching="fetching" :title="`Pridať ${input.language.value.toUpperCase()} kurz`"></submit-button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </visible-for>
    </admin-layout>
</template>

<script>
import AdminLayout from '../layout/AdminLayout'
import VisibleFor from '../component/VisibleFor'
import v8n from 'v8n'
import { validInput } from '@/util/validInput'
import { canSubmitForm } from '@/util/canSubmitForm'
import SubmitButton from '../component/SubmitButton'
import { formInput } from '@/util/formInput'
import VideoUpload from '../component/VideoUpload'
import { findIndex, forEachObjIndexed, includes, isEmpty, join, length, map, omit, pick, pipe, propEq, reduce, values } from 'ramda'
import { v4 } from 'uuid'
import { prepareData } from '@/util/prepareData'
import { imageMimeTypes } from '@/util/file'
import { COURSE_EXPIRATION_EXTENDED, COURSE_EXPIRATION_SHORTENED, COURSE_EXPIRATION_STANDARD, courseExpirations } from '@/util/course'
import { translateKey } from '@/util/translateKey'
import { watchInputChange } from '@/util/watchInputChange'
import swapItems from '../util/swapItems'
import { availableLocales } from '@/locale'

export default {
    beforeDestroy () {
        if (this.thumbnailSrc) {
            URL.revokeObjectURL(this.thumbnailSrc)
        }
    },
    components: { VideoUpload, SubmitButton, VisibleFor, AdminLayout },
    computed: {
        acceptImageMimeTypes () {
            return join(',', imageMimeTypes)
        },
        availableLocales () {
            return availableLocales
        },
        canSubmit () {
            return canSubmitForm(this.input)
        },
        courseExpirations () {
            return courseExpirations
        },
        fetching () {
            return this.$store.getters['course/isFetching']
        },
        uploadProgress () {
            return this.$store.getters['axios/getUploadProgress']
        },
    },
    created () {
        watchInputChange(this.input, this)
    },
    data () {
        const id = v4()
        
        return {
            input: {
                title: formInput({
                    validation: value => v8n()
                        .string()
                        .not
                        .empty()
                        .test(value),
                }),
                description: formInput({
                    valid: true,
                    dirty: true,
                    validation: value => v8n()
                        .optional(
                            v8n()
                                .string()
                                .test(value),
                            true,
                        ),
                }),
                groups: formInput({
                    value: [],
                    valid: true,
                    dirty: true,
                    validation: () => true,
                }),
                language: formInput({
                    value: this.$i18n.locale,
                    valid: true,
                    dirty: true,
                    validation: value => availableLocales.includes(value),
                }),
                expiration: {
                    [COURSE_EXPIRATION_SHORTENED]: formInput({
                        value: 14,
                        valid: true,
                        dirty: true,
                        validation: value => v8n()
                            .numeric()
                            .positive()
                            .test(value),
                    }),
                    [COURSE_EXPIRATION_STANDARD]: formInput({
                        value: 30,
                        valid: true,
                        dirty: true,
                        validation: value => v8n()
                            .numeric()
                            .positive()
                            .test(value),
                    }),
                    [COURSE_EXPIRATION_EXTENDED]: formInput({
                        value: 60,
                        valid: true,
                        dirty: true,
                        validation: value => v8n()
                            .numeric()
                            .positive()
                            .test(value),
                    }),
                },
                thumbnail: formInput({
                    value: null,
                    validation: value => ! ((value?.size === 0 || ! includes(value?.type, imageMimeTypes) || ! (value instanceof File))),
                }),
                videos: formInput({
                    value: [{ id }],
                    validation: value => reduce((prev, current) => prev && (! isEmpty(current?.title) && current?.file instanceof File), true, value),
                }),
            },
            thumbnailSrc: null,
            videoElements: [
                {
                    id: id,
                    element: VideoUpload,
                }],
        }
    },
    mounted () {
        this.$store.commit('axios/setUploadProgress', 0)
    },
    methods: {
        addVideoElement () {
            const video = {
                id: v4(),
                element: VideoUpload,
            }
            
            this.input.videos.value.push({ id: video.id })
            this.videoElements.push(video)
        },
        getVideoPayload (id) {
            const videos = this.input.videos.value
            
            return videos[findIndex(propEq('id', id), videos)]
        },
        removeVideoElement (id) {
            this.input.videos.value.splice(findIndex(propEq('id', id), this.input.videos.value), 1)
            this.videoElements.splice(findIndex(propEq('id', id), this.videoElements), 1)
        },
        handleChange (id, payload) {
            const videos = this.input.videos
            
            videos.value[findIndex(propEq('id', id), videos.value)] = payload
            videos.valid = videos.validation(videos.value)
        },
        handleGroupChange (event) {
            this.input.groups.value = event.target.value?.split(/\r?\n/)
        },
        handleMoveDown (id) {
            const index = findIndex(propEq('id', id), this.videoElements)
            
            if (index === length(this.videoElements) - 1) return
            
            this.input.videos.value = [...swapItems(index, index + 1, this.input.videos.value)]
            this.videoElements = [...swapItems(index, index + 1, this.videoElements)]
            this.scrollIntoVideoCard(id)
        },
        handleMoveUp (id) {
            const index = findIndex(propEq('id', id), this.videoElements)
            
            if (index === 0) return
            
            this.input.videos.value = [...swapItems(index, index - 1, this.input.videos.value)]
            this.videoElements = [...swapItems(index, index - 1, this.videoElements)]
            this.scrollIntoVideoCard(id)
        },
        handleThumbnailChange (event) {
            const file = event?.target?.files[0]
            
            this.input.thumbnail.value = file
            this.thumbnailSrc = URL.createObjectURL(file)
        },
        handleThumbnailDelete () {
            this.input.thumbnail.value = null
            this.thumbnailSrc = null
            this.$refs.thumbnail.value = ''
        },
        isValidInput (input) {
            return validInput(input)
        },
        scrollIntoVideoCard (id) {
            this.$nextTick(function () {
                const element = document.getElementById(`video-card-${id}`)
                
                if (! element) return
                
                window.scrollTo(0, element.offsetTop)
            })
        },
        submitForm () {
            // if (! this.canSubmit) return
            
            const data = prepareData(this.input)
            data.videos = pipe(values, map(pick(['title', 'file', 'group', 'description'])))(this.input.videos.value)
            
            // create form data
            const formData = new FormData()
            
            // fill data
            forEachObjIndexed((value, key) => {
                formData.append(key, value)
            }, omit(['groups', 'expiration', 'videos'], data))
            
            // fill groups
            forEachObjIndexed((value, key) => {
                formData.append(`groups[${key}]`, value)
            }, data?.groups)
            
            // append expirations
            forEachObjIndexed((value, key) => {
                formData.append(`expiration[${key}]`, value)
            }, data?.expiration)
            
            // append videos
            forEachObjIndexed((value, key) => {
                formData.append(`videos[${key}][title]`, value.title)
                formData.append(`videos[${key}][file]`, value.file)
                formData.append(`videos[${key}][description]`, value.description)
                
                if (!! value.group) {
                    formData.append(`videos[${key}][group]`, value.group)
                }
            }, data?.videos)
            
            // dispatch action
            this.$store.dispatch('course/storeCourse', formData)
            
        },
        translate (key) {
            return translateKey(key)
        },
    },
    name: 'CreateCourse',
}
</script>

<style lang="scss" scoped>
textarea {
    min-height: 150px !important;
}

.card {
    .card-body {
        position: relative;
        
        .btn-close {
            height: .75rem;
            margin: 0;
            opacity: 1;
            padding: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: .75rem;
        }
    }
    
    input, textarea {
        border-color: #212529;
    }
}

.thumbnail-image {
    object-fit: cover;
}
</style>
